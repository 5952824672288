<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h1>{{ $t('bots.title') }}</h1>
        <b-button squared to="/bots/add" variant="outline-success">
          {{ $t('bots.add') }}
        </b-button>
      </div>
    </div>

    <div class="row">
      <b-table :fields="fields" :items="bots" hover striped class="text-center">
        <template v-slot:cell(city)="data">
          <b-link :to="'/city/' + data.item.city.id">
            {{ data.item.city.name }}
          </b-link>
        </template>

        <template v-slot:cell(botToken)="data">
          <b-link :to="'/bots/' + data.item.id"> Go to settings </b-link>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IBot } from 'types';

type BotsFields = { key: keyof IBot | 'city' | 'orderNumber'; label: string };

@Component({ name: 'Bots' })
export default class Bots extends Vue {
  fields: BotsFields[] = [
    { key: 'orderNumber', label: '№' },
    { key: 'city', label: 'City' },
    { key: 'botToken', label: 'Settings' },
  ];

  showNotifyMenu = false;

  notifyText = '';
  notifyTextEn = '';
  bots: (IBot & { orderNumber: number })[] = [];

  async mounted() {
    const { data } = await this.axios.get<IBot[]>('/bots');

    this.bots = data.map((bot, index) => ({
      ...bot,
      orderNumber: index + 1,
    }));
  }
}
</script>
