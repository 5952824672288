"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORDER_STATUSES_COLORS = exports.KEYS_TO_STATUSES = exports.ORDER_STATUSES_LOCALIZATION_KEYS = exports.locales = exports.ADDITIONAL_MINUTES_OF_COOKING = exports.UPDATE_ORDER_STATUS_ID = exports.ORDER_STATUS_LOCALIZATION_KEYS = exports.MAX_BUTTONS_ON_SCREEN = exports.MINUTES_OF_COOKING = exports.BOT_COMMAND_START = exports.BOT_COMMAND_LANG = exports.DELIVERY_MULTIPLIER_CONFIG = exports.CUISINE_OPEN_NOW_NUMBER = exports.CUISINE_INFO_NUMBER = exports.CART_OK_ID = exports.REST_CLEAN_ID = exports.SHOW_TAGS_BUTTON = exports.SHOW_OPEN_NOW_BUTTON = exports.SHOW_INFO_BUTTON = exports.SHOW_TOP_TEN = exports.NO_PHOTO_IMAGE_ID = exports.IMAGE_DEFAULT_ID = exports.COURIER_CAN_ARRIVE_TO_TAKE_ORDER_IN_MINUTES = exports.THREAD_ID_LENGTH = exports.CRUTCH = exports.ADS_MAX_LENGTH = void 0;
const enums_1 = require("./enums");
exports.ADS_MAX_LENGTH = 800;
exports.CRUTCH = 1;
exports.THREAD_ID_LENGTH = 9;
exports.COURIER_CAN_ARRIVE_TO_TAKE_ORDER_IN_MINUTES = [0, 10, 15, 20, 30];
exports.IMAGE_DEFAULT_ID = 'https://images.unsplash.com/photo-1527497171728-16dba07f59a1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8TG9uZG9ufHx8fHx8MTY1MTcwMjQxOA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080';
exports.NO_PHOTO_IMAGE_ID = 'https://media.istockphoto.com/vectors/no-image-available-sign-vector-id922962354?k=20&m=922962354&s=612x612&w=0&h=f-9tPXlFXtz9vg_-WonCXKCdBuPUevOBkp3DQ-i0xqo=';
exports.SHOW_TOP_TEN = 'showTopTen';
exports.SHOW_INFO_BUTTON = 'showInfoButton';
exports.SHOW_OPEN_NOW_BUTTON = 'showOpenNow';
exports.SHOW_TAGS_BUTTON = 'showTagsButton';
exports.REST_CLEAN_ID = -100;
exports.CART_OK_ID = -100;
exports.CUISINE_INFO_NUMBER = -100;
exports.CUISINE_OPEN_NOW_NUMBER = -200;
exports.DELIVERY_MULTIPLIER_CONFIG = 'deliveryMultiplier';
exports.BOT_COMMAND_LANG = '/lang';
exports.BOT_COMMAND_START = '/start';
exports.MINUTES_OF_COOKING = [15, 20, 30, 40, 50, '60+'];
exports.MAX_BUTTONS_ON_SCREEN = 14;
exports.ORDER_STATUS_LOCALIZATION_KEYS = {
    [enums_1.EOrderStatus.CONFIRMED]: 'confirmed',
    [enums_1.EOrderStatus.SERVING]: 'cooking',
    [enums_1.EOrderStatus.READY]: 'endCooking',
    [enums_1.EOrderStatus.WAITING_FOR_COURIER]: 'delivered',
};
exports.UPDATE_ORDER_STATUS_ID = 'uos';
exports.ADDITIONAL_MINUTES_OF_COOKING = [60, 75, 90, 120, '<60'];
exports.locales = {
    values: [enums_1.LOCALES.EN, enums_1.LOCALES.RU],
    defaultValue: enums_1.LOCALES.EN,
};
exports.ORDER_STATUSES_LOCALIZATION_KEYS = [
    'NOT_PAID',
    'CREATED',
    'CONFIRMED',
    'SERVING',
    'READY',
    'WAITING_FOR_COURIER',
    'IN_RESTAURANT',
    'CALL_CUSTOMER',
];
exports.KEYS_TO_STATUSES = {
    CREATED: enums_1.EOrderStatus.CREATED,
    SERVING: enums_1.EOrderStatus.SERVING,
    CONFIRMED: enums_1.EOrderStatus.CONFIRMED,
    NOT_PAID: enums_1.EOrderStatus.NOT_PAID,
    WAITING_FOR_COURIER: enums_1.EOrderStatus.WAITING_FOR_COURIER,
    READY: enums_1.EOrderStatus.READY,
    IN_RESTAURANT: enums_1.EOrderStatus.IN_RESTAURANT,
    CALL_CUSTOMER: enums_1.EOrderStatus.CALL_CUSTOMER,
};
// CREATED - #fa4d56
// CONFIRMED - #ff832b
// SERVING - #f1c21b
// READY - #a56eff
// WAITING_FOR_COURIER - #24a148
exports.ORDER_STATUSES_COLORS = {
    [enums_1.EOrderStatus.DRAFT]: '#f1c21b',
    [enums_1.EOrderStatus.CREATED]: '#fa4d56',
    [enums_1.EOrderStatus.CONFIRMED]: '#ff832b',
    [enums_1.EOrderStatus.SERVING]: '#f1c21b',
    [enums_1.EOrderStatus.READY]: '#a56eff',
    [enums_1.EOrderStatus.WAITING_FOR_COURIER]: '#24a148',
    [enums_1.EOrderStatus.NOT_PAID]: '#bf2004',
    [enums_1.EOrderStatus.PAID]: '#24a148',
    [enums_1.EOrderStatus.IN_RESTAURANT]: '#a1c21b',
    [enums_1.EOrderStatus.CALL_CUSTOMER]: '#f1f11b',
};
