<template>
  <b-modal
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    v-model="isVersionChanged"
  >
    <template #modal-header>
      <div>
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mr-2 text-center"
        />

        <strong>{{ $t('home.pleaseReloadPage') }}</strong>
      </div>
    </template>

    {{ $t('home.pleaseReloadPageDetails') }}
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'VersionModal' })
export default class VersionModal extends Vue {
  get isVersionChanged() {
    return this.$store.state.appVersionIsChanged;
  }
}
</script>
