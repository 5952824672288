<template>
  <div v-if="user">
    <div class="row mb-3">
      <div class="col">
        <h1>{{ user.username }}</h1>
        <b-button
          squared
          v-on:click.prevent="onDelete"
          variant="outline-danger"
        >
          Удалить администратора
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form @submit.prevent="onSubmit">
          <label for="input-1">Юзернейм:</label>
          <b-form-group id="input-group-1">
            <b-form-input
              id="input-1"
              placeholder="Юзернейм"
              type="text"
              v-model="user.username"
            ></b-form-input>
          </b-form-group>

          <label for="input-2">Новый пароль:</label>
          <b-form-group id="input-group-2">
            <b-form-input
              id="input-2"
              placeholder="S3cR4tP@55w0rd"
              type="password"
              autocomplete="off"
              v-model="password"
            ></b-form-input>
          </b-form-group>

          <!-- City admins can't create any admin with any role other than the restaurateur -->

          <div v-if="!this.isCityAdmin">
            <label for="input-2">Роль:</label>
            <b-form-select
              v-model="user.roleId"
              :options="roles"
              class="mb-3"
            ></b-form-select>
          </div>

          <div v-if="user.roleId == 20">
            <label for="input-2">Ресторан:</label>
            <b-form-select
              v-model="user.restaurantId"
              :options="restaurants"
              class="mb-3"
            ></b-form-select>
          </div>

          <div v-if="[30, 40].includes(user.roleId)">
            <label for="input-2">Город:</label>
            <b-form-select
              v-model="user.cityId"
              :options="cities"
              class="mb-3"
            ></b-form-select>
          </div>

          <b-button
            squared
            type="submit"
            variant="outline-primary"
            :disabled="!user"
          >
            Обновить данные
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum } from 'types';

@Component({ name: 'User' })
export default class User extends Vue {
  @Prop() id!: string;

  password = '';
  user = null;
  restaurants = [];
  cities = [];
  roles = [
    { value: 10, text: 'Главный администратор 👑' },
    { value: 30, text: 'Администратор города 🏠' },
    { value: 20, text: 'Владелец ресторана 🍲' },
    { value: 40, text: 'Оператор 🍽' },
  ];

  get isCityAdmin() {
    return [RoleEnum.CITY_ADMIN, RoleEnum.OPERATOR].includes(
      this.$store.getters.roleId,
    );
  }

  mounted() {
    this.axios.get('/users/' + this.id).then((response) => {
      this.user = response.data;
    });

    this.axios.get('/restaurants').then((response) => {
      this.restaurants = response.data.map((rest) => ({
        value: rest.id,
        text: rest.name,
      }));
    });

    /**
     * City admins can't edit city of an admin, therefore they
     * won't need the list of cities.
     */
    if (!this.isCityAdmin) {
      this.axios.get('/cities').then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city.id,
          text: city.name,
        }));
      });
    }
  }

  onDelete() {
    this.axios
      .delete('/users/' + this.id)
      .then(() => this.$router.push({ path: '/users' }));
  }

  onSubmit() {
    const form = {
      ...this.user,
      password: this.password,
    };

    this.axios
      .put('/users/' + this.id, form)
      .then(() => this.$router.push({ path: '/users' }));
  }
}
</script>

<style scoped></style>
